import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "@babel/runtime/helpers/esm/objectSpread";
import PropTypes from 'prop-types';
import createConnector from '../core/createConnector';
import { cleanUpValue, refineValue, getCurrentRefinementValue, getIndexId } from '../core/indexUtils';

function getId() {
  return 'query';
}

function getAdditionalId() {
  return 'additionalVoiceParameters';
}

function getCurrentRefinementQuery(props, searchState, context) {
  var id = getId();
  var currentRefinement = getCurrentRefinementValue(props, searchState, context, id, '');

  if (currentRefinement) {
    return currentRefinement;
  }

  return '';
}

function getCurrentRefinementAdditional(props, searchState, context) {
  var id = getAdditionalId();
  var currentRefinement = getCurrentRefinementValue(props, searchState, context, id, '');

  if (currentRefinement) {
    return currentRefinement;
  }

  return {};
}

function _refine(props, searchState, nextRefinement, context) {
  var _nextValue;

  var id = getId();
  var voiceParams = getAdditionalId();
  var queryLanguages = props.language ? {
    queryLanguages: [props.language.split('-')[0]]
  } : {};
  var additionalQueryParameters = typeof props.additionalQueryParameters === 'function' ? _objectSpread({
    ignorePlurals: true,
    removeStopWords: true,
    optionalWords: nextRefinement
  }, props.additionalQueryParameters({
    query: nextRefinement
  })) : {};
  var nextValue = (_nextValue = {}, _defineProperty(_nextValue, id, nextRefinement), _defineProperty(_nextValue, voiceParams, _objectSpread({}, queryLanguages, additionalQueryParameters)), _nextValue);
  var resetPage = true;
  return refineValue(searchState, nextValue, context, resetPage);
}

function _cleanUp(props, searchState, context) {
  var interimState = cleanUpValue(searchState, context, getId());
  return cleanUpValue(interimState, context, getAdditionalId());
}

export default createConnector({
  displayName: 'AlgoliaVoiceSearch',
  propTypes: {
    defaultRefinement: PropTypes.string
  },
  getProvidedProps: function getProvidedProps(props, searchState, searchResults) {
    return {
      currentRefinement: getCurrentRefinementQuery(props, searchState, {
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      isSearchStalled: searchResults.isSearchStalled
    };
  },
  refine: function refine(props, searchState, nextRefinement) {
    return _refine(props, searchState, nextRefinement, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
  },
  cleanUp: function cleanUp(props, searchState) {
    return _cleanUp(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
  },
  getSearchParameters: function getSearchParameters(searchParameters, props, searchState) {
    var query = getCurrentRefinementQuery(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
    var additionalParams = getCurrentRefinementAdditional(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
    return searchParameters.setQuery(query).setQueryParameters(additionalParams);
  },
  getMetadata: function getMetadata(props, searchState) {
    var id = getId();
    var currentRefinement = getCurrentRefinementQuery(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
    return {
      id: id,
      index: getIndexId({
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      items: currentRefinement === null ? [] : [{
        label: "".concat(id, ": ").concat(currentRefinement),
        value: function value(nextState) {
          return _refine(props, nextState, '', {
            ais: props.contextValue,
            multiIndexContext: props.indexContextValue
          });
        },
        currentRefinement: currentRefinement
      }]
    };
  }
});