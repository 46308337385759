import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import isEqual from 'react-fast-compare';
import createConnector from '../core/createConnector';
import { getCurrentRefinementValue, refineValue, getResults } from '../core/indexUtils';
import { addAbsolutePositions, addQueryID } from '../core/utils';

function getId() {
  return 'page';
}

function getCurrentRefinement(props, searchState, context) {
  var id = getId();
  var page = 1;
  var currentRefinement = getCurrentRefinementValue(props, searchState, context, id, page);

  if (typeof currentRefinement === 'string') {
    return parseInt(currentRefinement, 10);
  }

  return currentRefinement;
}

function getStateWithoutPage(state) {
  var _ref = state || {},
      page = _ref.page,
      rest = _objectWithoutProperties(_ref, ["page"]);

  return rest;
}

function getInMemoryCache() {
  var cachedHits = undefined;
  var cachedState = undefined;
  return {
    read: function read(_ref2) {
      var state = _ref2.state;
      return isEqual(cachedState, getStateWithoutPage(state)) ? cachedHits : null;
    },
    write: function write(_ref3) {
      var state = _ref3.state,
          hits = _ref3.hits;
      cachedState = getStateWithoutPage(state);
      cachedHits = hits;
    }
  };
}

function extractHitsFromCachedHits(cachedHits) {
  return Object.keys(cachedHits).map(Number).sort(function (a, b) {
    return a - b;
  }).reduce(function (acc, page) {
    return acc.concat(cachedHits[page]);
  }, []);
}
/**
 * InfiniteHits connector provides the logic to create connected
 * components that will render an continuous list of results retrieved from
 * Algolia. This connector provides a function to load more results.
 * @name connectInfiniteHits
 * @kind connector
 * @providedPropType {array.<object>} hits - the records that matched the search state
 * @providedPropType {boolean} hasMore - indicates if there are more pages to load
 * @providedPropType {function} refine - call to load more results
 */


export default createConnector({
  displayName: 'AlgoliaInfiniteHits',
  getProvidedProps: function getProvidedProps(props, searchState, searchResults) {
    var _this = this;

    var results = getResults(searchResults, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    });
    this._prevState = this._prevState || {};
    var cache = props.cache || getInMemoryCache();

    if (this._cachedHits === undefined) {
      this._cachedHits = cache.read({
        state: searchState
      }) || {};
    }

    if (!results) {
      return {
        hits: extractHitsFromCachedHits(this._cachedHits),
        hasPrevious: false,
        hasMore: false,
        refine: function refine() {},
        refinePrevious: function refinePrevious() {},
        refineNext: function refineNext() {}
      };
    }

    var page = results.page,
        hits = results.hits,
        hitsPerPage = results.hitsPerPage,
        nbPages = results.nbPages,
        _results$_state = results._state;
    _results$_state = _results$_state === void 0 ? {} : _results$_state;

    var p = _results$_state.page,
        currentState = _objectWithoutProperties(_results$_state, ["page"]);

    var hitsWithPositions = addAbsolutePositions(hits, hitsPerPage, page);
    var hitsWithPositionsAndQueryID = addQueryID(hitsWithPositions, results.queryID);

    if (!isEqual(currentState, this._prevState)) {
      this._cachedHits = cache.read({
        state: searchState
      }) || {};
    }

    if (this._cachedHits[page] === undefined) {
      this._cachedHits[page] = hitsWithPositionsAndQueryID;
      cache.write({
        state: searchState,
        hits: this._cachedHits
      });
    }

    this._prevState = currentState;
    /*
      Math.min() and Math.max() returns Infinity or -Infinity when no argument is given.
      But there is always something in this point because of `this._cachedHits[page]`.
    */

    var firstReceivedPage = Math.min.apply(Math, _toConsumableArray(Object.keys(this._cachedHits).map(Number)));
    var lastReceivedPage = Math.max.apply(Math, _toConsumableArray(Object.keys(this._cachedHits).map(Number)));
    var hasPrevious = firstReceivedPage > 0;
    var lastPageIndex = nbPages - 1;
    var hasMore = lastReceivedPage < lastPageIndex;

    var refinePrevious = function refinePrevious(event) {
      return _this.refine(event, firstReceivedPage - 1);
    };

    var refineNext = function refineNext(event) {
      return _this.refine(event, lastReceivedPage + 1);
    };

    return {
      hits: extractHitsFromCachedHits(this._cachedHits),
      hasPrevious: hasPrevious,
      hasMore: hasMore,
      refinePrevious: refinePrevious,
      refineNext: refineNext
    };
  },
  getSearchParameters: function getSearchParameters(searchParameters, props, searchState) {
    return searchParameters.setQueryParameters({
      page: getCurrentRefinement(props, searchState, {
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }) - 1
    });
  },
  refine: function refine(props, searchState, event, index) {
    var pages = Object.keys(this._cachedHits || {}).map(Number);
    var lastReceivedPage = pages.length === 0 ? undefined : Math.max.apply(Math, _toConsumableArray(pages)); // If there is no key in `this._cachedHits`,
    // then `lastReceivedPage` should be `undefined`.

    if (index === undefined && lastReceivedPage !== undefined) {
      index = lastReceivedPage + 1;
    } else if (index === undefined) {
      index = getCurrentRefinement(props, searchState, {
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      });
    }

    var id = getId();

    var nextValue = _defineProperty({}, id, index + 1);

    var resetPage = false;
    return refineValue(searchState, nextValue, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }, resetPage);
  }
});